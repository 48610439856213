<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="logo-header">
        <ion-img src="/assets/logo.png"></ion-img>
      </div>

      <div class="location-block">
        <ion-avatar @click="UploadNewPhoto()">
          <Gravatar :email="email" />
          <!-- <img v-else :src="photoURL" /> -->
        </ion-avatar>
        <div>
          <h2>{{ displayName }}</h2>
          <!-- <p>{{ submissions.length }} SUGEZT Submissions</p> -->
        </div>
      </div>
      <div class="container">
        <ion-item>
          <ion-label position="floating">Name</ion-label>
          <ion-input v-model="displayName"></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="floating">Email</ion-label>
          <ion-input v-model="email" type="email"></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="floating">Update Password</ion-label>
          <ion-input
            :color="checkPasswordAreTheSame"
            v-model="password"
            clear-input
            type="password"
            placeholder="***********"
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating">Confirm Password</ion-label>
          <ion-input
            :color="checkPasswordAreTheSame"
            clear-input
            v-model="confirmPassword"
            type="password"
            placeholder="***********"
          ></ion-input>
        </ion-item>
      </div>
    </ion-content>
    <ion-footer>
      <div class="bottom-bar">
        <ion-button
          color="secondary"
          size="large"
          expand="full"
          @click="signOut"
          >Sign out</ion-button
        >
        <ion-button color="primary" size="large" expand="full" @click="saveInfo"
          >Save</ion-button
        >
      </div>
    </ion-footer>
  </ion-page>
</template>

<script>
import Gravatar from "@/components/Gravatar";
// import firebaseService from "../firebase-service";
import firebase from "firebase/app";
require("firebase/auth");
import {
  IonPage,
  IonContent,
  IonItem,
  IonAvatar,
  IonLabel,
  IonInput,
  IonButton,
  IonFooter,
  IonImg,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Profile",
  components: {
    IonLabel,
    IonInput,
    IonItem,
    IonContent,
    IonPage,
    IonAvatar,
    IonButton,
    IonFooter,
    IonImg,
    Gravatar
  },
  data() {
    const confirmPassword = "";
    const password = "";
    const displayName = firebase.auth().currentUser?.displayName;
    return {
      password,
      confirmPassword,
      displayName
    };
  },
  setup() {
    const email = (firebase.auth().currentUser) ? firebase.auth().currentUser.email : "";
    const photoURL = firebase.auth().currentUser?.photoURL;
    // const { submissions } = firebaseService();
    return {
      // submissions,
      email,
      photoURL,
    };
  },
  methods: {
    signOut() {
      firebase.auth().signOut();
    },
    saveInfo() {
      if (this.password.length > 1 && this.password == this.confirmPassword) {
        firebase.auth().currentUser.updatePassword(this.password)
      }
      if (this.email != firebase.auth().currentUser.email) {
        firebase.auth().currentUser.updateEmail(this.email)
      }

      firebase.auth().currentUser?.updateProfile({
        displayName: this.displayName,
      }).then(() => {
        this.$router.replace("/")
      });
    },
    UploadNewPhoto() {
      return;
    }
  },
  computed: {
    checkPasswordAreTheSame: function () {
      return (this.confirmPassword.length == 0 || this.password == this.confirmPassword) ? "" : "danger";
    },
  },
});
</script>

<style scoped>
.container {
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
}
.location-block {
  display: flex;
  align-items: center;
  margin: 2em auto;
  width: 90%;
  max-width: 600px;
  justify-content: center;
}
.location-block ion-avatar {
  border: 2px solid #fff;
  margin-left: 2em;
  transform: scale(1.5);
}
.location-block div {
  padding-left: 2em;
  font-weight: bold;
  font-size: 120%;
}
ion-footer {
  --background: #efeff4;
  --color: #000;
  background: #efeff4;
  color: #000;
  max-width: 600px;
  margin: 0 auto;
}
.bottom-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.bottom-bar ion-button {
  flex-basis: 50%;
}
</style>